import { Button } from '../../../../components/Button';
import styles from './index.module.scss';

export const WelcomeSection = ({ buttonHandle }) => {
  return (
    <div className={styles.container}>
      <div className={styles.inner}>
        <h2 className={styles.title}>lena laeva</h2>
        <h1 className={styles.subtitle}>Content creator</h1>
      </div>

      <Button text="Послуги" onClick={buttonHandle} />
    </div>
  );
};
