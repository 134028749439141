import { useRef } from 'react';
import CookieConsent from 'react-cookie-consent';
import { Footer } from '../../components/Footer';
import { About } from './components/About';
// import { AboutTariffs } from './components/AboutTariffs';
// import { FAQ } from './components/FAQ';
import { Contacts } from './components/Contacts';
import { Tariffs } from './components/Tariffs';
import { WelcomeSection } from './components/WelcomeSection';
import styles from './index.module.scss';

export const MainPage = () => {
  const tariffsRef = useRef(null);

  const scrollToTariffs = () => tariffsRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });

  return (
    <main className={styles.container}>
      <WelcomeSection buttonHandle={scrollToTariffs} />
      <About />
      {/* 
        <AboutTariffs />
       */}
      <div ref={tariffsRef}>
        <Tariffs />
      </div>
      {/* <FAQ /> */}
      <Contacts />
      <Footer />
      <CookieConsent>This website uses cookies to enhance the user experience.</CookieConsent>
    </main>
  );
};
