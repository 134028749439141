import { Link } from 'react-router-dom';
import styles from './index.module.scss';

export const Footer = () => (
  <div className={styles.container}>
    <div className={styles.wrapper}>
      <Link to="/impressum" className={styles.link}>
        Impressum
      </Link>
      <Link to="/datenschutz" className={styles.link}>
        Datenschutz
      </Link>
    </div>
    <p className={styles.copyright}>© 2024 Lena Laeva</p>
  </div>
);
