import CookieConsent from 'react-cookie-consent';
import { Footer } from '../../components/Footer';
import styles from './index.module.scss';

export const Impressum = () => {
  return (
    <>
      <div className={styles.container}>
        <h1>Impressum</h1>

        <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
        <p>
          Olena Abdullaieva
          <br />
          Lubecker str 83
          <br />
          39124 Magdeburg
        </p>

        <h2>Kontakt</h2>
        <p>
          Telefon: +491622544567
          <br />
          E-Mail: abdullaievaolena@gmail.com
        </p>

        <h2>Umsatzsteuer-ID</h2>
        <p>
          Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27 a Umsatzsteuergesetz:
          <br />
          102/200/14598
        </p>

        <h2>Gewerbeanmeldung</h2>
        <p>
          Die Gewerbeerlaubnis nach &sect;14 GewO / &sect;55 GewO GewO wurde am 02.01.2024 von folgender Stelle erteilt:
          01/04/2022 Ausl&auml;nderbeh&ouml;rde LH Magdeburg.
        </p>

        <h2>Redaktionell verantwortlich</h2>
        <p>Abdullaieva Olena</p>

        <h2>EU-Streitschlichtung</h2>
        <p>
          Die Europ&auml;ische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit:{' '}
          <a href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noopener noreferrer">
            https://ec.europa.eu/consumers/odr/
          </a>
          .<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.
        </p>

        <h2>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h2>
        <p>
          Wir nehmen an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teil. Zust&auml;ndig ist
          die Universalschlichtungsstelle des Zentrums f&uuml;r Schlichtung e.V., Stra&szlig;burger Stra&szlig;e 8,
          77694 Kehl am Rhein (
          <a href="https://www.verbraucher-schlichter.de" rel="noopener noreferrer" target="_blank">
            https://www.verbraucher-schlichter.de
          </a>
          ).
        </p>

        <p>
          Quelle: <a href="https://www.e-recht24.de">https://www.e-recht24.de</a>
        </p>
      </div>
      <CookieConsent>This website uses cookies to enhance the user experience.</CookieConsent>
      <Footer />
    </>
  );
};
