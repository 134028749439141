import SubPhoto from '../../../../assets/images/IMG_2789.JPG';
import styles from './index.module.scss';

export const About = () => {
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <h2 className={styles.title}>Про мене</h2>
        <h3 className={styles.text}>
          Я Лєна Лаєва
          <br />
          Експерт по просуванню та введенню інстаграм, контент-мейкер та візуалізатор для особистих брендів.
        </h3>
        <h4 className={styles.text}>
          <b>Я зможу вам допомогти, якщо ви хочете:</b>
        </h4>
        <ul className={styles.options_wrapper}>
          <li className={styles.options}>створити упаковку блогу, яка розкриватиме вас як особистість і експерта;</li>
          <li className={styles.options}>навчитись створювати візуал для сторінки;</li>
          <li className={styles.options}>навчитись створювати контент, який тримає увагу та заохочує до продажів;</li>
          <li className={styles.options}>розробити стратегію просування;</li>
          <li className={styles.options}>розробити стратегію продажу послуг чи інфопродуктів.</li>
        </ul>
        <img src={SubPhoto} alt="Lena Laeva" width="100%" className={styles.photo} />
        <div className={styles.inner}>
          <p className={styles.subtitle}>Моя місія:</p>
          <p className={styles.text}>
            Показати світу, що немає нічого кращого за справжність. Зірвати шаблони про ідеальний контент, які навʼязані
            суспільством. Розкрити вас через контент та візуал. Зробити так, щоб глядач відчував емоції через ваші фото
            та відео, щоб він зловив ваш вайб та енергію. Щоб контент продавав вас не тільки як експерта, а і як
            особистість.
          </p>
        </div>
      </div>
    </div>
  );
};
