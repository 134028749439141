import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../components/Button';
import { Footer } from '../../components/Footer';
import styles from './index.module.scss';

export const ErrorPage = () => {
  const navigate = useNavigate();

  const [isCompletedOrder, setIsCompletedOrder] = useState(false);

  const { pathname } = window.location;

  const checkOrderId = pathname.includes('cs_') && pathname.length > 20;

  useEffect(() => {
    if (checkOrderId) {
      setIsCompletedOrder(true);
    } else {
      setIsCompletedOrder(false);
    }
  }, []);

  const handleChatClick = () => {
    if (isCompletedOrder) {
      window.open('https://t.me/+UCS_2L0JZ5ViMDYy', '_blank');
    } else {
      navigate('/');
    }
  };

  const checkTitle = isCompletedOrder ? 'Ваша оплата пройшла успішно!' : 'Сторінка не знайдена';

  const checkDescription = isCompletedOrder
    ? 'Переходьте в Телеграм чат нашого практикуму, щоб познайомитись з учасниками і почати навчання ❤️'
    : 'Неправильно набрано адресу або такої сторінки на сайті більше не існує.';

  const checkButtonTitle = isCompletedOrder ? 'Перейти в чат' : 'Перейти на головну';

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <h1 className={styles.title}>{checkTitle}</h1>

        <h2 className={styles.text}>{checkDescription}</h2>

        <Button text={checkButtonTitle} onClick={handleChatClick} />
      </div>
      <Footer />
    </div>
  );
};
