import styles from './index.module.scss';

export const Button = ({ text = '', onClick = () => null, disabled = false }) => (
  <button
    type="button"
    className={`${styles.button} ${disabled && styles.button_disabled}`}
    onClick={onClick}
    disabled={disabled}
  >
    <span className={styles.title}>{text}</span>
  </button>
);
