import Photo from '../../../../assets/images/IMG_3284.jpeg';
import styles from './index.module.scss';

export const Contacts = () => {
  return (
    <div className={styles.container}>
      <h2 className={styles.title}>контакти</h2>
      <img src={Photo} alt="Lena Laeva" width="100%" />
      <div className={styles.wrapper}>
        <a
          href="https://www.instagram.com/lena.laeva/"
          target="_blank"
          rel="noreferrer"
          className={`${styles.link} ${styles.withoutBorder}`}
        >
          <span className={styles.link_title}>instagram</span>
          <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="40">
            <path
              d="M677-333.5 659-352l115-115H177v-25.5h597l-115.5-115 18-18.5L823-480 677-333.5Z"
              fill="transparent"
              stroke="transparent"
            />
          </svg>
        </a>
        {/* <a href="https://t.me/LaevaLenabot" target="_blank" rel="noreferrer" className={styles.link}>
          <span className={styles.link_title}>telegram</span>
          <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="40">
            <path
              d="M677-333.5 659-352l115-115H177v-25.5h597l-115.5-115 18-18.5L823-480 677-333.5Z"
              fill="transparent"
              stroke="transparent"
            />
          </svg>
        </a> */}
        <a href="https://www.tiktok.com/@lenalaeva/" target="_blank" rel="noreferrer" className={styles.link}>
          <span className={styles.link_title}>tiktok</span>
          <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="40">
            <path
              d="M677-333.5 659-352l115-115H177v-25.5h597l-115.5-115 18-18.5L823-480 677-333.5Z"
              fill="transparent"
              stroke="transparent"
            />
          </svg>
        </a>
        <a
          href="https://www.facebook.com/profile.php?id=61552221290836"
          target="_blank"
          rel="noreferrer"
          className={styles.link}
        >
          <span className={styles.link_title}>facebook</span>
          <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="40">
            <path
              d="M677-333.5 659-352l115-115H177v-25.5h597l-115.5-115 18-18.5L823-480 677-333.5Z"
              fill="transparent"
              stroke="transparent"
            />
          </svg>
        </a>
      </div>
    </div>
  );
};
