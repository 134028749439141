import { Button } from '../../../../components/Button';
import styles from './index.module.scss';

export const Tariffs = () => {
  const handleConsultingClick = () => {
    window.open('https://buy.stripe.com/5kA7tifMB6HE1K84gl', '_blank');
  };
  // const handleComplectClick = () => {
  //   window.open('https://buy.stripe.com/4gw9BqgQF1nk0G4fZ1', '_blank');
  // };
  const handleIndividualClick = () => {
    window.open('https://buy.stripe.com/fZe5la8k9aXUdsQ8wD', '_blank');
  };

  return (
    <div className={styles.container}>
      <div>
        <div className={styles.card}>
          {/* <p className={styles.subtitle}>консультація</p> */}
          <div className={styles.wrapper}>
            <div className={styles.title_line} />
            <h3 className={styles.title}>консультація</h3>
            <div className={styles.title_line} />
          </div>
          {/* <p className={styles.text}></p> */}
          <ul className={styles.options_wrapper}>
            <li className={styles.options}>
              <span className={styles.options_label}>Формат: </span> Персональний відеодзвінок на якому ми розбираємо
              всі питання стосовно вашого розвитку в інстаграмі.
            </li>
            <li className={styles.options}>
              <span className={styles.options_label}>Тривалість: </span> 1,5-2 години.
            </li>
            <li className={styles.options}>
              <span className={styles.options_label}>Результат: </span> Ми пройдемось по всім вашим питанням і після
              консультації у вас буде повне розуміння як дійти до своєї цілі з покроковим планом дій.
            </li>
          </ul>
          <div className={styles.inner}>
            {/* <p className={styles.price_old}>69€</p> */}
            <p className={styles.price}>39€</p>
            {/* <p className={styles.price_description}>*залишилось 5 місць</p> */}
          </div>
        </div>
        <Button text="Придбати" onClick={handleConsultingClick} />
      </div>
      {/* <div>
        <div className={styles.card}>
          <p className={styles.subtitle}>консультація</p>
          <div className={styles.wrapper}>
            <div className={styles.title_line} />
            <h3 className={styles.title}>
              Генеральне
              <br />
              прибирання
            </h3>
            <div className={styles.title_line} />
          </div>
          <p className={styles.text}>
            Для тих, хто вже веде блог, має експертність, сформував мету, визначив теми, вміє знімати якісний контент,
            але не знає як масштабуватись і зробити квантовий стрибок вперед.
          </p>
          <p className={styles.text}>
            Ми наведемо порядок в існуючому профілі, підтягнемо всі хвости, закриємо пробіли по всім недостаючим
            питанням та покроково розберемо як покращити ситуацію.
          </p>
          <ul className={styles.options_wrapper}>
            <li className={styles.options}>
              <span className={styles.options_label}>Формат: </span> Персональний відеодзвінок на якому ми розбираємо
              всі питання стосовно кратного покращення вашого запиту.
            </li>
            <li className={styles.options}>
              <span className={styles.options_label}>Тривалість: </span> 1,5-2 години.
            </li>
            <li className={styles.options}>
              <span className={styles.options_label}>Результат: </span> Детальний аналіз профілю, контенту, візуала
              сторінки та презентація з планом дій для покращення.
            </li>
          </ul>
          <div className={styles.inner}>
            <p className={styles.price_old}>89€</p>
            <p className={styles.price}>69€</p>
            <p className={styles.price_description}>*залишилось 3 місця</p>
          </div>
        </div>
        <Button text="Придбати" onClick={handleComplectClick} />
      </div> */}
      <div>
        <div className={styles.card}>
          <p className={styles.subtitle}>консультація</p>
          <div className={styles.wrapper}>
            <div className={styles.title_line} />
            <h3 className={styles.title}>
              Упаковка
              <br />
              профіля
            </h3>
            <div className={styles.title_line} />
          </div>
          <p className={styles.text}>
            Для тих, хто вже вирішив вести сторінку і одразу якісно упакувати профіль, хто не хоче витрачати часу на
            обдумування, а одразу діяти і приходити до результату.
          </p>
          <p className={styles.text}>
            Ми визначимо цілі та теми блогу, пропишемо план, позиціонування, наповнимо шапку профілю та теми для
            хайлайтс. Я розроблюю візуал для сторінки та прописую 15 тем для кожного поста.
          </p>
          <ul className={styles.options_wrapper}>
            <li className={styles.options}>
              <span className={styles.options_label}>Формат: </span> Індивідуальна робота (відеодзвінок та листування
              протягом тижня).
            </li>
            <li className={styles.options}>
              <span className={styles.options_label}>Тривалість: </span> відеодзвінок 1.5-2 години + 7 днів зворотнього
              зв'язку.
            </li>
            <li className={styles.options}>
              <span className={styles.options_label}>Результат: </span> Упакований профіль на 15 постів, готовий до
              просування та залучення нових читачів.
            </li>
          </ul>
          <div className={styles.inner}>
            <p className={styles.price}>109€</p>
            {/* <p className={styles.price_old}>109€</p>
            <p className={styles.price_description}>*залишилось 1 місце</p> */}
          </div>
        </div>
        <Button text="Придбати" onClick={handleIndividualClick} />
      </div>
    </div>
  );
};
